// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {

    .inner-wrapper {
         min-height: 100vh;
        min-height: -webkit-fill-available;

        // IMPORTANT
        display: flex;
        flex-direction: column;
    }

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

@media (min-width: 1280px) {
    .shop-header {
        margin-bottom: 24px;
    }
}

.top-bar {
    display: flex;
    justify-content: space-between;
    .line-height();
    position: relative;
    gap: 20px;
    padding: 12px 0;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: @light-bg;
    }
}

.header-shipment {

    &:before {
        content: '\e918';
        font-family: icomoon;
        float: left;
        font-size: 24px;
        margin-right: 8px;
    }
}

.header-contact {
    display: flex;
    gap: 24px;

    li {

    }

    a {
        display: block;
        color: @font-color;

        &:after {
            font-family: icomoon;
            float: left;
            font-size: 24px;
            margin-right: 8px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .phone:after {
        content: '\e916';
    }

    .email:after {
        content: '\e914';
    }
}

a.header-login {
    color: @font-color;

    &:before {
        content: '\e919';
        font-family: icomoon;
        float: left;
        font-size: 24px;
        margin-right: 8px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    height: 112px;
    justify-content: space-between;
}

// LOGO
.logo {
    display: flex;

    img {
        width: auto;
        height: 40px;
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 24px;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box,
    #wishlist-box, 
    a.log-button {
        position: relative;
        display: flex;
        align-items: center;

        &:before {
            font-family: icomoon;
            font-size: 32px;
            line-height: 1;
            color: @font-color;
        }

        em {
            display: none;
        }

        &:hover {

            &:before {
                color: @main-color;
            }
        }
    }

    #cart-box {
        gap: 10px;

        &:before {
            content: '\e90e';
        }

        .cart-text-wrapper {
            display: flex;
            flex-direction: column;
            line-height: 1.2;
            color: @font-color;
            text-transform: uppercase;

            span {
                font-size: 12px;
            }

            b {
                font-size: 14px;
            }
        }

        i {
            position: absolute;
            top: 1px;
            left: 19px;
            color: white;
            background-color: @main-color;
            border-radius: 50%;
            width: 16px;
            line-height: 16px;
            text-align: center;
            font-style: normal;
            font-size: 10px;
            font-weight: bold;
        }
    }

    #wishlist-box {

        &:before {
            content: '\e913';
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }

        &:after {
            content: attr(data-wishlist-count);
            position: absolute;
            top: 1px;
            left: 23px;
            color: white;
            background-color: @main-color;
            border-radius: 50%;
            width: 16px;
            line-height: 16px;
            text-align: center;
            font-style: normal;
            font-size: 10px;
            font-weight: bold;
        }
    }

    a.log-button {
        display: none;

        &:before {
            content: '\e919';
        }
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 524px;
        z-index: 100;

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 56px 0 24px;
            background-color: @light-bg;
            border-radius: 24px;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: 0;

            &::-webkit-input-placeholder {
                color: @header-color;
            }
            &::-moz-placeholder {
                color: @header-color;
            }
            &:-ms-input-placeholder {
                color: @header-color;
            }
            &:-moz-placeholder {
                color: @header-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 48px;
            color: @font-color;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '\e917';
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: white;
    width: 720px;
    padding: 24px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// MAIN BAR
#main-bar {
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: @font-color;
    }
}

// MAINMENU
.mainmenu {
    display: flex;
    justify-content: space-between;

    a {
        display: flex;
        height: 56px;
        color: white;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;

        &:hover {
            color: #89C07E;
        }
    }
}

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    .sidebar-title {
        display: flex;
        align-items: center;
        color: white;
        background-color: @main-color;
        font-size: 16px;
        padding: 12px 24px;
        border-top-left-radius: var(--borderRadius);
        border-top-right-radius: var(--borderRadius);
    }

    #tree {
        display: flex;
        flex-direction: column;

        .sidebar-title {
            order: -1;

            span {
                width: 16px;
                height: 1px;
                background-color: white;
                position: relative;
                margin-right: 8px;
                border-radius: 1px;

                &:before,
                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background-color: white;
                    border-radius: 1px;
                }

                &:before {
                    top: -5px;
                }

                &:after {
                    bottom: -5px
                }
            }
        }

        button {
            display: none;
        }

        > nav {
            border: 1px solid @border-color;
            border-top: 0;
            border-bottom-left-radius: var(--borderRadius);
            border-bottom-right-radius: var(--borderRadius);
            padding: 8px 23px;
        }

        li {

            &.downarrow {

                > a {
                    
                }

                > ul {
                    display: block;
                }
            }
        
            ul {
                display: none;
            }
        }

        li.t0 {

            &:not(:last-child) {
                border-bottom: 1px solid @border-color;
            }

            > a {
                display: block;
                padding: 16px 0;
                color: @font-color;
                font-weight: bold;
            }

            &.downarrow.parent > a {
                border-bottom: 1px solid @border-color;
            }

            > ul {
                padding: 8px 0;
            }
        }

        a {
            position: relative;

            &:hover {
                text-decoration: underline;
            }
        }

        li:not(.t0) {

            a {
                display: block;
                color: @font-color;
                padding: 8px 0;
            }
        }

        li.t1 > a {
            padding-left: 16px;
        }

        li.t2 > a {
            padding-left: 32px;
        }

        li.t3 > a {
            padding-left: 48px;
        }

        li.selected > a {
            color: @main-color;
        }
    }
}

.sidebar-products {

    .wrapper {
        border: 1px solid @border-color;
        border-top: 0;
        border-bottom-left-radius: var(--borderRadius);
        border-bottom-right-radius: var(--borderRadius);
        padding: 8px 23px;
    }
}

// SIDEBAR PRODUCTS
.sidebar-product {
    border-bottom: 1px solid @border-color;
    display: flex;
    align-items: center;
    padding: 16px 0;

    &:last-child {
        border-bottom: 0;
    }

    > div {

        &:nth-child(1) {
            width: 60px;
            margin-right: 8px;
            flex-shrink: 0;
        }

        &:nth-child(2) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    &:hover .sidebar-product-name {
        text-decoration: underline;
    }
}

.sidebar-product-image {
    width: 100%;
    height: 60px; 
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

.sidebar-product-badge {
    display: block;
    color: white;
    background-color: @error-color;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
}

a.sidebar-product-name {
    font-size: 14px;
    overflow: hidden;
    color: @font-color;
    display: block;
    font-weight: bold;

    &:first-letter {
        text-transform: uppercase;
    }
}

.sidebar-product-price {
    display: block;
    line-height: 1.2;
    font-size: 14px;

    b,
    del {
        color: @font-color;

        &:after {
            content: ' zł';
        }
    }

    b {
    }

    del {
        opacity: .6;
        margin-left: 8px;
    }
}

// SIDE BANNER
.side-banner {
    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
        position: relative;
        aspect-ratio: 300 / 135;
        overflow: hidden;
        border-radius: var(--borderRadius);

        &:hover {

            img {
                transform: scale(1.04);
            }
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .2s ease-in-out;
    }

    b {
        position: absolute;
        color: white;
        font-size: 16px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 16px;
        width: fit-content;
        text-align: center;
        max-width: 90%;
        background-color: rgba(27, 39, 39, 0.9);
        padding: 4px 42px;
        border-radius: var(--borderRadius);
    }
}

// SIDE PRODUCERS BANNER
.side-producers-banner {
    
    .wrapper {
        padding: 24px 23px;
        border: 1px solid @border-color;
        border-top: 0;
        border-bottom-left-radius: var(--borderRadius);
        border-bottom-right-radius: var(--borderRadius);
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    figure {
        width: 100%;
    }

    a {

        &:hover {

            img {
                transform: scale(1.08);
            }
        }
    }

    img {
        display: block;
        margin: 0 auto;
        width: auto;
        height: auto;
        max-height: 32px;
        max-width: 100%;
        transition: transform .2s ease-in-out;
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 16px;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

.product-description {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    color: @font-color;
    font-size: 14px;
    padding: 16px;
    background-color: rgba(255,255,255,.9);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all .2s ease-in-out;

    p {
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        max-height: ~"calc(5 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

a.product-name {
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    font-size: 14px;
    font-weight: bold;
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 5px;
    z-index: 1;
}

.product-price {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    b, del {
        line-height: 1.2;
        color: @font-color;

        &:after {
            content: ' zł';
        }
    }

    b {
    }

    del {
        opacity: .6;
        font-size: 14px;
    }
}

.product-add,
.product-wishlist {
    width: 40px;
    height: 40px;
    border-radius: var(--borderRadius);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
        font-family: icomoon;
    }
}

.product-add {
    background-color: @main-color;
    color: white;
    font-size: 24px;

    &:after {
        content: '\e90e';
    }

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: @main-dark;
    }
}

.product-wishlist {
    margin-left: auto;
    background-color: #E0E8E2;
    color: @font-color;
    font-size: 24px;

    &:after {
        content: '\e913';
    }

    &:hover {
        color: @main-color;
    }

    &.existing {
        background-color: @error-color;
        color: white;
    }
}

.product-badges {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;

    span {
        color: white;
        background-color: @font-color;
        line-height: 22px;
        padding: 0 14px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;

        &.promo-badge {
            margin-left: auto;
            background-color: @error-color;
        }
    }
}

@media (min-width: 1280px) {
    .product {

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: calc(var(--prodGap) / 2 * -1);
            width: 1px;
            height: 100%;
            background-color: @border-color;
        }

        &:nth-child(4n),
        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    .product:hover {

        .product-description {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }

        .product-name {
            text-decoration: underline;
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 12px 0;
    margin-bottom: 12px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 12px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}